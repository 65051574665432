import React from "react";
import Img from "gatsby-image";
import { scroller } from "react-scroll";
import Drop from "../../../svg/drop.svg";
import useLanguage from "../../Hooks/useLanguage";

const PetrolMainScreen = ({ title, bg }) => {
  const langToggle = useLanguage;
  function scrollToSection() {
    scroller.scrollTo("petroleum-section", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  return (
    <section className="home-mainscreen">
      <Img fluid={bg.childImageSharp.fluid} className="main-screen_bg" />
      <div className="main-screen_center">
        <h1 className="h1">
          {langToggle(title.title_ua, title.title_ru, title.title_en)}
        </h1>
        <span className="sub-title">
          {langToggle(
            title.sub_title_ua,
            title.sub_title_ru,
            title.sub_title_en
          )}
        </span>
      </div>

      <button className="scroll-down" onClick={scrollToSection}>
        <Drop />
      </button>
    </section>
  );
};

export default PetrolMainScreen;
