import React from "react";
import useLanguage from "../../Hooks/useLanguage";
import Img from "gatsby-image";

const ReactMarkdown = require("react-markdown");

const PetroleumPage = ({ about, feature, important }) => {
  const langToggle = useLanguage;
  return (
    <section className="petroleum-section">
      <div className="container-fluid">
        <div className="petroleum-item">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-5 col-12">
              <Img
                fluid={about.image.image.childImageSharp.fluid}
                className="about-img"
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-7 col-12">
              <h3 className="h3">
                {langToggle(about.title_ua, about.title_ru, about.title_en)}
              </h3>
              <ReactMarkdown
                source={langToggle(
                  about.content_ua,
                  about.content_ru,
                  about.content_en
                )}
              />
            </div>
          </div>
          <div className="features-title">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-7 col-12">
                <h3 className="h3">
                  {langToggle(
                    feature.title_ua,
                    feature.title_ru,
                    feature.title_en
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className="features-items">
            <div className="row">
              {feature.Features.map((item, index) => {
                return (
                  <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                    <p key={index} className="features-items_content">
                      {langToggle(
                        item.content_ua,
                        item.content_ru,
                        item.content_en
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="important">
            <h3 className="h3">
              {langToggle(
                important.title_ua,
                important.title_ru,
                important.title_en
              )}
            </h3>
          </div>
          <div className="important-wrapper">
            <div className="important-items">
              <div className="important-item">
                <p className="important-item_title">
                  {langToggle(
                    important.important.content_ua,
                    important.important.content_ru,
                    important.important.content_en
                  )}
                </p>
              </div>
              <div className="important-item_img">
                <Img
                  fluid={important.important.image.childImageSharp.fluid}
                  className="item-img"
                />
                <div className="icon-wrapper">
                  <img
                    src={important.important.icon.publicURL}
                    alt="icon"
                    className="important-icon"
                  />
                </div>
              </div>
              <div className="important-item_img second-item">
                <Img
                  fluid={important.important.image.childImageSharp.fluid}
                  className="item-img"
                />
                <div className="icon-wrapper">
                  <img
                    src={important.important_second.icon.publicURL}
                    alt="icon"
                    className="important-icon"
                  />
                </div>
              </div>
              <div className="important-item">
                <p className="important-item_title">
                  {langToggle(
                    important.important_second.content_ua,
                    important.important_second.content_ru,
                    important.important_second.content_en
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PetroleumPage;
