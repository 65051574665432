import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import useLanguage from "../components/Hooks/useLanguage";
import PetrolMainScreen from "../components/Pages/PetroleumPage/MainScreen";
import PetroleumPage from "../components/Pages/PetroleumPage/PetroleumPage";

const PetrolPage = ({ data }) => {
  const langToggle = useLanguage;
  const title = data?.strapiPetroleum?.mainscreen;
  const bg = data.strapiPetroleum.bg_image;
  const about = data.strapiPetroleum.About_Petrol;
  const feature = data.strapiPetroleum.Features_title;
  const important = data.strapiPetroleum.Important_title;
  const seo = data.strapiPetroleum.seo;
  return (
    <Layout>
      <SEO
        title={langToggle(title.title_ua, title.title_ru, title.title_en)}
        description={langToggle(
          seo.seo_description_ua,
          seo.seo_description_ru,
          seo.seo_description_en
        )}
        keywords={[
          langToggle(
            seo.seo_keywords_ua,
            seo.seo_keywords_ru,
            seo.seo_keywords_en
          ),
        ]}
      />
      <PetrolMainScreen title={title} bg={bg} />
      <PetroleumPage about={about} feature={feature} important={important} />
    </Layout>
  );
};

export default PetrolPage;

export const query = graphql`
  query PetrolQuery {
    strapiPetroleum {
      seo {
        seo_description_ua
        seo_description_ru
        seo_description_en
        seo_keywords_ua
        seo_keywords_ru
        seo_keywords_en
      }
      mainscreen {
        title_ua
        title_ru
        title_en
        sub_title_ua
        sub_title_ru
        sub_title_en
      }
      bg_image {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      About_Petrol {
        title_ua
        title_ru
        title_en
        content_ua
        content_ru
        content_en
        image {
          image {
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      Features_title {
        title_ua
        title_ru
        title_en
        Features {
          content_ua
          content_ru
          content_en
        }
      }
      Important_title {
        title_ua
        title_ru
        title_en
        important {
          content_ua
          content_ru
          content_en
          image {
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          icon {
            publicURL
          }
        }
        important_second {
          content_ua
          content_ru
          content_en
          icon {
            publicURL
          }
        }
      }
    }
  }
`;
